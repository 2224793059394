<template>
  <div class="editFrom">
    <el-dialog
      title="添加教师"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="700px"
    >
      <el-form
        ref="form"
        label-position="right"
        label-width="102px"
        :rules="rules"
        :model="form"
      >
        <el-form-item label="学 校：" prop="schoolIdSub">
          <el-select
            v-model="form.schoolIdSub"
            filterable
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in schoolList"
              :key="item.schoolId"
              :label="item.schoolName"
              :value="item.schoolId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓 名：" prop="teacherName">
          <el-input
            v-model="form.teacherName"
            placeholder="请输入姓名"
            maxlength="20"
            show-word-limit
            class="text-nums"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名：" prop="username" class="is-required">
          <el-input
            v-model="form.username"
            placeholder="请输入用户名"
            maxlength="20"
            show-word-limit
            class="text-nums"
          ></el-input>
        </el-form-item>

        <el-form-item label="密 码：" prop="password">
          <el-input
            v-model="form.password"
            placeholder="请输入密码"
            maxlength="20"
            show-word-limit
            class="text-nums"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input
            v-model="form.phone"
            placeholder="请输入联系方式"
            maxlength="20"
            show-word-limit
            class="text-nums"
          ></el-input>
        </el-form-item>
        <el-form-item label="分 类：" prop="type">
          <el-input
            v-model="form.type"
            placeholder="请输入分类"
            maxlength="20"
            show-word-limit
            class="text-nums"
          ></el-input>
        </el-form-item>
        <el-form-item label="科 目：" style="width: 100%; margin-bottom: 0">
          <el-button type="text" @click="handleCheckAllChange">全选</el-button>
          <el-checkbox-group v-model="subjectIds">
            <el-checkbox
              v-for="item in subjectOptions"
              :key="item.subjectId"
              :label="item.subjectId"
              >{{ item.subjectName }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="submitLoading" type="primary" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addExamteacher,
  editExamteacher,
  unionsubschool,
} from "@/core/api/exam/examTeacher";
import { getStore } from "@/core/util/store";
export default {
  name: "EditFrom",
  components: {},
  data() {
    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名名称"));
      }
      setTimeout(() => {
        const lint = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/;

        if (!lint.test(value)) {
          callback(new Error("只能含有汉字，字母，数字或下划线"));
        } else {
          callback();
        }
      }, 500);
    };
    var passLint = (rule, value, callback) => {
      if (!value) {
        callback();
      }
      setTimeout(() => {
        const lint = /^[\u4e00-\u9fa5]{0,}$/;

        let str = value.split("");
        let type = false;
        str.map((item) => {
          if (lint.test(item)) {
            type = true;
          }
        });
        if (type) {
          callback(new Error("密码不能存在中文"));
        } else {
          callback();
        }
      }, 500);
    };
    return {
      form: {
        schoolIdSub: "",
        teacherName: "",
        password: "",
        username: "",
        phone: "",
        type: "",
      },
      rules: {
        schoolIdSub: [
          { required: true, message: "请选择学校", trigger: "change" },
        ],
        teacherName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        username: [{ validator: validateName, trigger: "blur" }],
        password: [{ validator: passLint, trigger: "blur" }],
      },
      subjectIds: [],
      schoolList: [],
      subjectOptions: [],
      dialogVisible: false,
      submitLoading: false,
    };
  },
  created() {},
  methods: {
    async init(item) {
      let schoolId = getStore({ name: "school_id" });

      this.dialogVisible = true;
      this.subjectOptions = this.$parent.subjectOptions;
      const res = await unionsubschool({
        examId: this.$route.query.examId,
        needUnit: true,
      });
      this.schoolList = res.data.data;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.form = this.$options.data().form;
        this.form.schoolId = schoolId;
        this.form.examId = this.$route.query.examId;
        this.subjectIds = [];
        if (item) {
          this.form = Object.assign({}, item);
          let subjectId = item.subjectId.split(",").map((item) => Number(item));
          subjectId = subjectId.filter((item) => item);
          this.subjectIds = subjectId;
        }
      });
    },
    handleCheckAllChange() {
      this.subjectIds = this.subjectOptions.map((item) => item.subjectId);
    },
    submit() {
      this.submitLoading = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$set(this.form, "subjectId", this.subjectIds.toString());

          if (!this.form.id) {
            addExamteacher({ ...this.form })
              .then(() => {
                this.dialogVisible = false;
                this.$message.success("新增成功");
                this.$parent.getTeacherList();
                this.submitLoading = false;
                this.subjectIds = [];
                this.$parent.getSchoolteachertype();
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            editExamteacher({ ...this.form })
              .then(() => {
                this.dialogVisible = false;
                this.$message.success("编辑成功");
                this.$parent.getTeacherList();

                this.subjectIds = [];
                setTimeout(() => {
                  this.submitLoading = false;
                }, 500);
                this.$parent.getSchoolteachertype();
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        } else {
          this.submitLoading = false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.editFrom {
  .el-form {
    display: flex;
    flex-wrap: wrap;
  }
  .el-form-item {
    width: 50%;
    margin-bottom: 18px;
    .el-checkbox {
      min-width: 110px;
      margin-right: 0;
    }
    ::v-deep {
      .el-form-item__label {
        margin-bottom: 0;
        line-height: 32px;
      }
      .el-form-item__content {
        line-height: 32px;
      }
    }
  }
}
</style>
