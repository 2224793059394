<template>
  <div class="ImportFile">
    <el-dialog title="批量添加用户" :visible.sync="dialogVisible" width="598px">
      <ElImportUpload
        ref="ElImportUpload"
        @upLoadSucc="upLoadSucc"
        @upLoadLoading="upLoadLoading"
      >
        <template slot="tips">
          <div class="tips">
            <div class="tips-title">【提示】</div>

            1、模板中姓名为必填项，最多为20个汉字;<br />
            2、若模板中用户名为空，导入系统后可自动生成用户名;<br />
            3.1、当“选择导入学校”为“所有学校”时，模板中需要填写学校名称，学校名称需要和提供到系统中的一致;<br />
            3.2、当“选择导入学校“已指定学校时，模板中则无需填写学校名称;<br />
            3.3、若出现“所选择导入学校"与模板中所填写学校名称不一致时，“所选择导入学校”以指定学校为准；<br />
            4、密码只能含有数字，字母或者特殊字符，若未填写，系统默认为123456；<br />
            5、模板中分类为非必填项，需要时可填写自定义分类;
          </div>
        </template>
        <template slot="down">
          <div class="down-box">
            <div>
              选择学校：
              <el-select
                v-model="schoolId"
                placeholder="请选择"
                filterable
                @change="check()"
              >
                <el-option label="所有学校" value=""> </el-option>
                <el-option
                  v-for="item in schoolList"
                  :key="item.schoolId"
                  :label="item.schoolName"
                  :value="item.schoolId"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <img src="@/assets/uExam/icon-down.png" alt="" />
              下载模版：
              <span class="down-tips" @click="downloadModel()">
                阅卷教师信息模板.xlsx
              </span>
            </div>
          </div>
        </template>
      </ElImportUpload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          导 入
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImportUpload from "@/components/ElImportUpload";
import { fileDownloadByUrl } from "@/core/util/util";
import { unionsubschool } from "@/core/api/exam/examTeacher";
import qs from "qs";
export default {
  name: "ImportFile",
  components: { ElImportUpload },
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      schoolList: [],
      schoolId: "",
    };
  },
  created() {},
  methods: {
    downloadModel() {
      let url =
        "https://static.wtjy.com/exam/template/%E8%81%94%E8%80%83-%E9%98%85%E5%8D%B7%E6%95%99%E5%B8%88%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.xlsx";
      let name = "阅卷教师信息模板.xlsx";
      fileDownloadByUrl(url, name);
    },
    upLoadLoading() {
      this.btnLoading = false;
    },
    submit() {
      this.btnLoading = true;
      this.$refs.ElImportUpload.submit();
    },
    upLoadSucc(data) {
      this.data = data;
      setTimeout(() => {
        this.$parent.getNewList();
      }, 2000);

      // this.dialogVisible = false;
    },
    check() {
      let data = {
        schoolIdSub: this.schoolId,
        examId: this.$route.query.examId,
      };
      this.$refs.ElImportUpload.ImportURL =
        "/exam/examteacher/import?" + qs.stringify(data);
    },
    async init() {
      this.dialogVisible = true;
      // this.schoolList = this.$parent.schoolList;
      this.$nextTick(() => {
        let data = {
          schoolIdSub: this.schoolId,
          examId: this.$route.query.examId,
        };
        let url = "/exam/examteacher/import?" + qs.stringify(data);
        let accept = ".xlsx,.xls";
        this.$refs.ElImportUpload.init(url, accept);
      });
      const res = await unionsubschool({
        examId: this.$route.query.examId,
        needUnit: true,
      });
      this.schoolList = res.data.data;
    },
  },
};
</script>
<style scoped lang="scss">
.ImportFile {
}
</style>
