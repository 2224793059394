<template>
  <div class="addAdmin">
    <el-dialog
      title="引用联考端用户"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="1273px"
    >
      <div class="content-box">
        <div class="left-box">
          <div class="filter-box">
            <div class="filter-item">
              学校：
              <el-select
                v-model="search.schoolIdSub"
                placeholder="全部"
                filterable
                @change="handleClick"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in schoolList"
                  :key="item.schoolId"
                  :label="item.schoolName"
                  :value="item.schoolId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="filter-item">
              学段：
              <el-select
                v-model="search.level"
                placeholder="全部"
                @change="handleClick"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="filter-item">
              学科：
              <el-select
                v-model="search.subjectId"
                placeholder="全部"
                @change="handleClick"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in subjectOptions"
                  :key="item.subjectId"
                  :label="item.subjectName"
                  :value="item.subjectId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="filter-item">
              分类：
              <el-select
                v-model="search.type"
                placeholder="全部"
                @change="handleClick"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in typeOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="filter-item">
              <el-input
                v-model="search.keyword"
                placeholder="请输入姓名或用户名"
                @keyup.enter.native="handleClick"
              >
                <i
                  slot="suffix"
                  class="el-icon-search el-input__icon"
                  @click="handleClick"
                >
                </i>
              </el-input>
            </div>
          </div>
          <!-- <p class="user-tips">
              系统用户 <span>{{ teacherList.length }}</span> 人
            </p> -->
          <div class="list">
            <div class="list-header">
              <div class="checkbox" style="text-align: left">
                <el-checkbox v-model="checkAll" @change="setList">
                </el-checkbox>
              </div>
              <div class="col">姓名</div>
              <div class="col">用户名</div>
              <div :class="['school']">学校</div>
            </div>
            <div class="list-content" style="height: 433px">
              <el-checkbox-group v-model="checkList" @change="setSingleList">
                <virtualList
                  :list-data="teacherList"
                  :container-height="'433px'"
                >
                  <template slot-scope="{ item }">
                    <div class="list-item">
                      <div class="checkbox" style="text-align: left">
                        <el-checkbox :key="item.id" :label="item.id">
                          {{ "" }}
                        </el-checkbox>
                      </div>
                      <div class="col">
                        <a-tooltip>
                          <template slot="title"> {{ item.name }} </template>
                          {{ item.name }}
                        </a-tooltip>
                      </div>
                      <div class="col">
                        <a-tooltip>
                          <template slot="title">
                            {{ item.username }}
                          </template>
                          {{ item.username }}
                        </a-tooltip>
                      </div>
                      <div class="school">
                        <a-tooltip>
                          <template slot="title">
                            {{ item.schoolName }}
                          </template>
                          {{ item.schoolName }}
                        </a-tooltip>
                      </div>
                    </div>
                  </template>
                </virtualList>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div>
          <div class="filter-box">
            <!-- <div class="filter-item">
              学校：
              <el-select
                v-model="searchR.schoolId"
                placeholder="请选择学段"
                clearable
                @change="filterTeacher"
              >
                <el-option
                  v-for="item in schoolList"
                  :key="item.schoolId"
                  :label="item.schoolName"
                  :value="item.schoolId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="filter-item">
              <el-input
                v-model="searchR.keyword"
                placeholder="请输入姓名或用户名"
                @keyup.enter.native="filterTeacher"
              >
                <i
                  slot="suffix"
                  class="el-icon-search el-input__icon"
                  @click="filterTeacher"
                >
                </i>
              </el-input>
            </div> -->
          </div>
          <p class="user-tips">
            已选用户 <span>{{ checkAllTeacher.length }}</span> 人
            <el-button
              class="clear-btn"
              type="text"
              icon="el-icon-refresh-left"
              @click="clearAll()"
            >
              清空
            </el-button>
          </p>
          <el-table
            :data="checkTeacher"
            tooltip-effect="dark"
            style="width: 100%"
            border
          >
            <el-table-column
              prop="name"
              label="姓名"
              width="140"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="username"
              label="用户名"
              width="140"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="schoolName"
              label="学校"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column align="center" width="40">
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  icon="el-icon-close"
                  @click="delItem(row)"
                >
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryUnionuser,
  saveUnionuser,
  unionsubschool,
} from "@/core/api/exam/examTeacher";
// import { getStore } from "@/core/util/store";
import { schoolunionuserType } from "@/core/api/cms/geo.js";
import { getSubjectList } from "@/core/util/util";
import { levelOptions } from "@/core/util/constdata";
import virtualList from "@/components/virtualList";
export default {
  name: "AddAdmin",
  components: { virtualList },
  data() {
    return {
      checkAll: false,
      dialogVisible: false,
      submitLoading: false,
      listLoading: false,

      typeOptions: [],
      schoolList: [],
      //   左侧选中列表
      checkList: [],
      //   左侧列表
      teacherList: [],
      //   右侧显示的列表
      checkTeacher: [],
      //   右侧所有的列表
      checkAllTeacher: [],
      search: {
        keyword: "",
        level: "",
        schoolIdSub: "",
        subjectId: "",
        type: "",
      },
      searchR: {
        keyword: "",
        schoolId: "",
        //         examId: 28671
        // schoolId: 20265
        // schoolIdSub: 20221
      },
      levelOptions: levelOptions(),
      subjectOptions: getSubjectList(),
    };
  },
  created() {},
  methods: {
    submit() {
      this.conditionImportTeacher();
    },
    async conditionImportTeacher() {
      this.submitLoading = true;

      try {
        await saveUnionuser({
          schoolTeacherVOS: this.checkAllTeacher,
          examId: this.$route.query.examId,
          flag: 0,
        });

        this.dialogVisible = false;
        this.$parent.getNewList();

        setTimeout(() => {
          this.submitLoading = false;
        }, 500);
      } catch {
        this.submitLoading = false;
      }
    },
    setList() {
      let arr = this.teacherList.map((item) => item.id);
      if (this.checkAll) {
        this.checkList = this.checkList.concat(arr);
      } else {
        this.checkList = this.checkList.filter(
          (item) => arr.indexOf(item) == -1
        );
      }
      this.checkList = [...new Set(this.checkList)];
      setTimeout(() => {
        this.getTeacherList();
      }, 500);
    },
    setSingleList() {
      setTimeout(() => {
        this.getTeacherList();
      }, 500);
    },
    // 依次获取出左侧需要被删除的数据  被添加的数据
    getTeacherList() {
      let arr = this.teacherList.map((item) => {
        // return {
        //   id: .id,
        //   username: item.username,
        //   name: item.name,
        //   schoolName: item.schoolName,
        //   schoolId: item.schoolId,
        // };
        return item;
      });
      let oldArr = this.checkAllTeacher.map((item) => item.id);
      let addList = arr.filter(
        (item) => this.checkList.includes(item.id) && !oldArr.includes(item.id)
      );
      let delList = oldArr.filter((item) => !this.checkList.includes(item));
      this.checkAllTeacher = this.checkAllTeacher.concat(addList);
      this.checkAllTeacher = this.checkAllTeacher.filter(
        (item) => !delList.includes(item.id)
      );
      this.filterTeacher();
    },
    delItem(row) {
      this.checkList = this.checkList.filter((item) => item != row.id);
      this.getTeacherList();
    },
    clearAll() {
      this.checkAllTeacher = [];
      this.checkTeacher = [];
      this.checkList = [];
    },
    filterTeacher() {
      let { schoolId, keyword } = this.searchR;

      this.checkTeacher = this.checkAllTeacher.filter((item) => {
        if (
          ((schoolId && item.schoolId == schoolId) || !schoolId) &&
          (!keyword ||
            (keyword && item.name.indexOf(keyword) != -1) ||
            item.username.indexOf(keyword) != -1)
        ) {
          return item;
        }
      });
    },
    //获取老师信息
    normalFetchList() {
      // let schoolId = getStore({ name: "school_id" });

      let data = {
        examId: this.$route.query.examId,
      };
      Object.assign(data, this.search);
      this.listLoading = true;
      queryUnionuser(data)
        .then((response) => {
          this.teacherList = response.data.data.filter(
            (item) => item.ifChoose == "false"
          );
          // console.log(this.teacherList);
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleClick() {
      this.checkAll = false;
      this.normalFetchList();
    },
    getSchoolunionuserType() {
      schoolunionuserType().then((res) => {
        // console.log(res);
        this.typeOptions = res.data.data.filter((item) => item);
      });
    },
    async init() {
      this.search = this.$options.data().search;
      this.searchR = this.$options.data().searchR;
      this.checkTeacher = [];
      this.checkList = [];
      this.checkAllTeacher = [];
      this.checkAll = false;
      this.dialogVisible = true;

      this.getSchoolunionuserType();
      // this.schoolList = this.$parent.schoolList;
      // this.normalFetchList();
      const res = await unionsubschool({
        examId: this.$route.query.examId,
        needUnit: true,
      });
      this.schoolList = res.data.data;
      if (this.schoolList.length > 0) {
        this.search.schoolIdSub = this.schoolList[0].schoolId;
      }

      this.normalFetchList();
    },
  },
};
</script>
<style scoped lang="scss">
.addAdmin {
  .list {
    border: 1px solid #dee0e7;
    .list-header {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #f5f7fa;
      border-bottom: 1px solid #dee0e7;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 18px;
      .checkbox {
        width: 10%;
      }
      .col {
        // width: 20%;
        flex: 1;
        font-size: 12px;
        text-align: center;
      }
      .col + .col {
        margin-left: 2.5%;
      }

      .school {
        text-align: center;
        width: 150px;
        font-size: 12px;
        margin-right: 16px;
      }
      .col + .school {
        margin-left: 2.5%;
      }
    }
    .list-content {
      overflow-y: auto;
      .list-item {
        box-sizing: border-box;
        padding: 0 18px;
        display: flex;
        width: 100%;
        align-items: center;
        height: 40px;
        .checkbox {
          width: 10%;
        }
        .col {
          flex: 1;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          overflow: hidden;
        }
        .col + .col {
          margin-left: 2.5%;
        }
        .school {
          text-align: center;
          width: 150px;
          font-size: 12px;
        }
        .col + .school {
          margin-left: 2.5%;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 3px;
      }
      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: #dcdfe6;
      }
    }
  }
  .clear-btn {
    color: #f57574;
    ::v-deep span {
      margin-left: 0;
    }
  }

  .content-box {
    display: flex;
    border: 1px solid #dee0e7;
    .left-box {
      ::v-deep {
        .el-table__body-wrapper {
          height: 431px;
        }
      }
    }
    ::v-deep {
      .el-table td.el-table__cell,
      .el-table th.el-table__cell.is-leaf {
        border: none;
      }

      .el-table__body-wrapper {
        height: 499px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        ///*<!--修改 滑块 -->*/
        // border: 1px solid #DEE1E7;
        &::-webkit-scrollbar-thumb {
          background-color: #dee1e7;
        }
      }
    }
    .user-tips {
      margin-bottom: 18px;
      span {
        color: #2474ed;
      }
    }
    .filter-box {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 18px;

      .filter-item {
        display: flex;
        white-space: nowrap;
        align-items: center;
        margin-bottom: 18px;
      }
    }
    > div {
      width: 50%;
      padding: 18px;
      &:first-child {
        border-right: 1px solid #dee0e7;
      }
    }
  }
}
</style>
