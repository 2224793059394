<template>
  <div v-loading="loading" class="examTeacher">
    <detailsHeader
      ref="detailsHeader"
      router-url="/exam/unionExam"
      :query="{
        examId: $route.query.examId,
      }"
    >
    </detailsHeader>

    <div class="card">
      <div>
        <div class="filter">
          <div class="filter-item">
            学校：
            <el-select
              v-model="search.schoolIdSub"
              clearable
              filterable
              placeholder="全部"
              @change="filterData"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-item">
            科目：
            <el-select
              v-model="search.subjectId"
              clearable
              filterable
              placeholder="全部"
              @change="filterData"
            >
              <el-option
                v-for="item in showSubjectList"
                :key="item.subjectId"
                :label="item.subjectName"
                :value="item.subjectId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-item">
            分类：
            <el-select
              v-model="search.type"
              clearable
              filterable
              placeholder="全部"
              @change="filterData"
            >
              <el-option
                v-for="(item, index) in typeOptions"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-item">
            搜索：
            <el-input
              v-model="search.keyword"
              placeholder="输入姓名或用户名"
              clearable
              style="width: 204px"
              @input="filterData"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="btn-box">
        <span>阅卷教师列表</span>
        <div class="btns">
          <el-button type="primary" @click="add()">
            <img src="@/assets/newExam/student/icon-add.svg" alt="" />
            <img
              class="on"
              src="@/assets/newExam/student/icon-add.svg"
              alt=""
            />
            添加
          </el-button>

          <!-- <el-button plain type="primary" @click="importUniUser">
            导入联考用户
          </el-button> -->
          <!-- <el-button plain type="primary"> 导入校内用户 </el-button> -->
          <el-dropdown type="primary">
            <el-button type="primary" style="margin: 0 10px">
              <img src="@/assets/newExam/student/icon-import.svg" alt="" />
              <img
                class="on"
                src="@/assets/newExam/student/icon-import.svg"
                alt=""
              />
              导入
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="importTeacher()">
                批量添加用户
              </el-dropdown-item>
              <el-dropdown-item @click.native="importUniUser()">
                引用联考端用户
              </el-dropdown-item>
              <el-dropdown-item @click.native="importSchoolUser()">
                引用校端教师
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="primary" @click="exportTeacherDialog()">
            <img src="@/assets/newExam/student/icon-export.svg" alt="" />
            <img
              class="on"
              src="@/assets/newExam/student/icon-export.svg"
              alt=""
            />
            导出
          </el-button>
          <el-button type="primary" @click="deleteStudent()">
            <img src="@/assets/uExam/icon-del.png" alt="" />
            <img class="on" src="@/assets/uExam/icon-del.png" alt="" />
            批量删除
          </el-button>
          <el-button type="danger" @click="clearBySchool()">
            <img src="@/assets/uExam/clear-white.png" alt="" />
            <img class="on" src="@/assets/uExam/clear-white.png" alt="" />
            <!-- <i class="el-icon-refresh-left"></i> -->
            清空
          </el-button>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%"
        tooltip-effect="dark"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column prop="teacherName" label="姓名" align="center" />
        <el-table-column prop="username" label="用户名" align="center" />
        <el-table-column prop="password" label="密码" align="center" />
        <el-table-column prop="schoolName" label="学校" align="center" />
        <el-table-column
          prop="subjectName"
          label="科目"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.subjectName || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="分类" align="center">
          <template slot-scope="{ row }">
            {{ row.type || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="联系方式" align="center">
          <template slot-scope="{ row }">
            {{ row.phone || "-" }}
          </template>
        </el-table-column>

        <!-- <el-table-column prop="encoding" label="编码" align="center" /> -->
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button v-if="row.isEdit == 1" type="text" @click="edit(row)">
              修改
            </el-button>
            <el-button type="text" style="color: #f56c6c" @click="del(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <!-- <el-pagination
          :current-page="search.current"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="search.size"
          layout="prev, pager, next,sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination> -->

        <el-pagination
          v-if="total > 0"
          :current-page="search.current"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="search.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      custom-class="dialog"
      :width="dialog"
      :before-close="handleClose"
    >
      <div v-if="title === '清空阅卷教师'">
        选择需要清空的学校 ：
        <el-select
          v-model="schoolId"
          clearable
          filterable
          placeholder="请选择"
          style="width: 192px"
        >
          <el-option
            v-for="item in clearList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="title === `导出阅卷教师信息`">
        选择学校：
        <el-select
          v-model="exportSchool"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option
            v-if="title == '导出阅卷教师信息' && (roleId == 6 || roleId == 1)"
            label="全部"
            value="0"
          ></el-option>
          <el-option
            v-for="item in schoolList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          v-if="title === `清空阅卷教师`"
          type="primary"
          :loading="submitLoading"
          @click="clearTeacher"
        >
          确 定</el-button
        >
        <el-button
          v-if="title === `导出阅卷教师信息`"
          type="primary"
          :loading="submitLoading"
          @click="exportTeacher"
        >
          确 定</el-button
        >
      </span>
    </el-dialog>
    <delVerify ref="delVerify"></delVerify>
    <editFrom ref="editFrom"></editFrom>
    <ImportFile ref="ImportFile"></ImportFile>
    <addUser ref="addUser"></addUser>
    <addUnTeacher ref="addUnTeacher"></addUnTeacher>
    <clearTeacher ref="clearTeacher"></clearTeacher>
  </div>
</template>
<script>
import {
  examteacher,
  delExamteacher,
  examinationuserBatch,
  // deleteBatch,
  getSchoolteacher,
  exportExamTeacher,
  existexamschool,
  schoolteachertype,
  existsubject,
} from "@/core/api/exam/examTeacher";

import { getObj } from "@/core/api/exam/exam";
import detailsHeader from "@/components/detailsHeader.vue";
import { getStore } from "@/core/util/store";

import delVerify from "./component/delVerify.vue";
import editFrom from "./component/editFrom.vue";
import ImportFile from "./component/ImportFile.vue";
import addUser from "./component/addUser.vue";
import addUnTeacher from "./component/addUnTeacher.vue";
import clearTeacher from "./component/clearTeacher.vue";
import { fileDownloadByUrl, getSubjectList } from "@/core/util/util";
// import {  } from "@/core/util/util";
export default {
  name: "ExamTeacher",
  components: {
    detailsHeader,
    clearTeacher,

    delVerify,
    editFrom,
    ImportFile,
    addUser,
    addUnTeacher,
  },

  data() {
    const { roleId, schoolId } = JSON.parse(
      localStorage.getItem("jzjx-userInfo")
    ).content;
    return {
      initPasswrod: "",
      search: {
        current: 1,
        size: 50,
        schoolId: schoolId,
      },
      tableData: [],
      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      dialogVisible: false,
      title: "",
      total: 0,
      schoolList: [],
      clearList: [],
      subjectList: [],
      loading: false,
      showTab: false,
      multipleSelection: [],
      dialog: "30%",
      form: {
        schoolId: schoolId,
      },
      rules: {
        schoolIdSub: [
          { required: true, message: "请选择学校", trigger: "blur" },
        ],
        teacherName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      subjectOptions: [],
      showSubjectList: [],
      isIndeterminate: false,
      checkAll: false,
      subjectIds: [],
      submitLoading: false,
      typeOptions: [],
      batch: {},
      examInformation: {},
      choose: {},
      checkList: [],
      chooseList: [],
      schoolId: 0,
      exportSchool: "",
      chooseloading: false,
      roleId: roleId,
      examTeacherImportURL:
        "/exam/examteacher/import?examId=" + this.$route.query.examId,
    };
  },
  created() {
    this.subjectOptions = getSubjectList(this.$route.query.levelId);
  },
  mounted() {
    this.getSchoollistbyexamid(); // 获取参与考试的学校
    this.getExamDetail();
    this.getTeacherList();
    this.getSchoolteachertype();
  },
  methods: {
    exportTeacherDialog() {
      this.dialogVisible = true;
      this.title = "导出阅卷教师信息";
      this.dialog = "347px";
    },
    exportTeacher() {
      this.submitLoading = true;
      exportExamTeacher({
        ...this.search,
        schoolId: this.exportSchool,
        examId: this.$route.query.examId,
      })
        .then((res) => {
          fileDownloadByUrl(res.data.data.url, res.data.data.name);
          this.submitLoading = false;
          this.dialogVisible = false;
          this.schoolId = "";
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    handleImportBefore(file) {
      if (!(file.name.endsWith(".xlsx") || file.name.endsWith(".xls"))) {
        this.$message.error("只能上传 Excel 文件！");
        return false;
      }
      let is10M = file.size / 1024 / 1024 <= 10;
      if (!is10M) {
        this.$message.error("文件大小不能超过 10 MB！");
        return false;
      }
      return true;
    },
    handleOnsuccess(res) {
      if (res.data.indexOf("https") != -1) {
        window.open(res.data);
      }
      this.dialogVisible = false;
    },
    delItem(index) {
      this.checkList.splice(index, 1);
    },
    async clearTeacher() {
      let data = this.clearList.filter(
        (item) => item.schoolId == this.schoolId
      )[0];

      this.$refs.delVerify.init(data, 0);

      this.dialogVisible = false;
      // this.submitLoading = false;
    },
    clearBySchool() {
      // this.title = "清空阅卷教师";

      this.$refs.clearTeacher.init();
    },
    async getImportTeacher() {
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      const res = await examinationuserBatch({
        ...this.choose,
        examId: this.$route.query.examId,
        flag: 1,
        schoolId: schoolId,
      });
      this.chooseList = res.data.data;
    },

    async batchImport() {
      this.submitLoading = true;
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      try {
        await examinationuserBatch({
          ...this.batch,
          examId: this.$route.query.examId,
          schoolId: schoolId,
          flag: 0,
        });
        this.submitLoading = false;
        this.search.current = 1;
        this.dialogVisible = false;
        this.getTeacherList();
        this.getSchoolteachertype();
        this.batch = {};
      } catch {
        this.submitLoading = false;
      }
    },
    importUniUser() {
      this.$refs.addUnTeacher.init();
    },

    importSchoolUser() {
      this.$refs.addUser.init();
      this.$refs.addUser.title = "引用校端教师";
    },
    async getImportSchoolTeacher() {
      this.chooseloading = true;
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      const res = await getSchoolteacher({
        ...this.choose,
        schoolId: schoolId,
        examId: this.$route.query.examId,
      });
      this.chooseList = res.data.data;
      this.chooseloading = false;
    },

    async getSchoolteachertype() {
      const res = await schoolteachertype({
        examId: this.$route.query.examId,
      });
      this.typeOptions = res.data.data;
    },
    importTeacher() {
      this.$refs.ImportFile.init();
    },

    handleClose() {
      this.dialogVisible = false;
      this.batch = {};
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      this.form = {
        schoolId: schoolId,
      };
      this.chooseList = [];
    },
    hidden() {
      this.multipleSelection = [];
      this.showTab = false;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteStudent() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择需要批量删除的阅卷教师",
          type: "warning",
        });
        return;
      }
      this.$refs.delVerify.init(this.multipleSelection, 1);
      // let data = this.multipleSelection.map((item) => item.id);
      // deleteBatch([...data])
      //   .then(() => {
      //     this.getTeacherList();
      //     this.$message.success("删除成功");
      //     this.showTab = false;
      //   })
      //   .catch(() => {
      //     this.getTeacherList();
      //   });
    },
    edit(row) {
      this.$refs.editFrom.init(row);
      // this.dialogVisible = true;
      // this.title = "编辑阅卷教师";
      // this.form = { ...row };
      // this.$set(this.form, "username", row.userName);
      // this.subjectIds = this.form.subjectId
      //   .split(",")
      //   .map((item) => Number(item));
    },
    del(row) {
      this.$confirm("此操作将永久删除该教师, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delExamteacher({ id: row.id }).then(() => {
            this.$message.success("删除成功");
            this.getTeacherList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.search.size = val;
      this.getTeacherList();
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getTeacherList();
    },
    filterData() {
      this.search.current = 1;
      this.getTeacherList();
    },
    getNewList() {
      this.search.current = 1;
      this.getSchoolteachertype();
      this.getSchoollistbyexamid();
      this.getTeacherList();
    },
    getExistsubject() {
      let data = {
        examId: this.$route.query.examId,
      };
      existsubject(data)
        .then((res) => {
          this.showSubjectList = res.data.data;
        })
        .catch(() => {});
    },
    async getTeacherList() {
      this.loading = true;
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      try {
        const res = await examteacher({
          ...this.search,
          examId: this.$route.query.examId,
          schoolId: schoolId,
        });
        this.loading = false;
        this.tableData = res.data.data.records;
        this.multipleSelection = [];
        this.total = res.data.data.total;
      } catch {
        this.loading = false;
      }
      this.getExistsubject();
    },
    async getSchoollistbyexamid() {
      const res = await existexamschool({
        examId: this.$route.query.examId,
      });
      this.schoolList = res.data.data;
    },
    // async getExistexamschool() {
    //   const res = await existexamschool({
    //     examId: this.$route.query.examId,
    //   });

    //   // this.schoolList = res.data.data;
    // },
    async getExamDetail() {
      // 获取 考试详情
      const res = await getObj(this.$route.query.examId);
      this.examInformation = res.data.data;
      this.subjectList = res.data.data.paperList;
    },
    add() {
      this.$refs.editFrom.init();
    },
    downloadModel() {
      window.open(
        "https://jzjx-resource.oss-cn-chengdu.aliyuncs.com/analy/template/%E9%98%85%E5%8D%B7%E6%95%99%E5%B8%88%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.xls"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.examTeacher {
  padding-top: 18px;
  .el-button {
    ::v-deep i {
      font-size: 16px;
    }
  }
  .btn-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 18px 0;
    > span {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 24px 0;
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .btn {
      color: #2474ed;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.ps {
  background: #fff3f3;
  border: 1px dashed #d92020;
  box-sizing: border-box;
  padding: 10px 13px;
  font-size: 14px;
  color: #605757;
  margin-bottom: 24px;
  i {
    color: #d92020;
  }
}
.title {
  color: #2474ed;
  font-size: 16px;
  margin-bottom: 24px;
  font-weight: bold;
}
.filter {
  display: flex;
  align-items: center;
  font-size: 14px;
  flex-wrap: wrap;
  justify-content: flex-start;
  &-item {
    margin-right: 18px;
    .el-select {
      width: 140px;
    }
  }
}
.mb24 {
  margin-bottom: 24px;
}
.choose {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  .choose-Box {
    flex: 1;
    margin-right: 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      background: #f5f7fa;
      box-sizing: border-box;
      padding: 0 24px;
      border: 1px solid #dee1e3;
      font-size: 14px;
      .clear {
        color: #d92020;
      }
    }
    .content {
      height: 279px;
      border: 1px solid #dee1e3;
      overflow-y: auto;
      border-top: none;
      box-sizing: border-box;
      padding: 24px;
      .el-checkbox {
        display: block;
        margin-bottom: 24px;
      }
      .content-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #2474ed;
        font-size: 14px;
        margin-bottom: 24px;
        i {
          color: #101011;
          cursor: pointer;
          &:hover {
            color: #2474ed;
          }
        }
      }
    }
  }
}
</style>
